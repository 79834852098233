import React, { useEffect, useContext, useRef, useReducer } from "react";
import PropTypes from "prop-types";
import { get, has } from "lodash";
import moment from "moment";
import cx from "classnames";
import { isMobile, withOrientationChange } from "react-device-detect";
import socketIOClient from "socket.io-client";
import { useFullscreen } from "@umijs/hooks";
import { parse } from "query-string";

import Countdown from "./Countdown";
import Controls from "./Controls";
import Player, { reducer, initialState } from "./Player";
import { BroadcastContext } from "../../widgets";
import { Icon } from "../Media";
import { useThemeContext } from "../Layout/ThemeContext";
import { ymNumber } from "../../constants";

import "./Broadcast.scss";
import { removeEmptyKeys } from "../../helpers";

function Broadcast({ data, slug, isLandscape, srcDescription }) {
	const { theme } = useThemeContext();
	const { items } = useContext(BroadcastContext);

	const video = useRef(null);

	const { isFullscreen, setFull, exitFull, toggleFull } = useFullscreen({
		dom: () => get(video, "current.player.player.player", null),
	});

	const [state, dispatch] = useReducer(
		reducer,
		{
			...initialState,
			broadcast: data,
		},
	);

	function hit() {
		if (typeof ym !== "undefined") {
			// eslint-disable-next-line no-undef
			ym(
				ymNumber,
				"reachGoal",
				"broadcast",
				{
					AB: {
						broadcast: slug,
					},
				},
			);
		}
	}

	function makeDelimeter(users = 1) {
		if (+users > 1) {
			if (!isMobile) {
				return <span dangerouslySetInnerHTML={{ __html: "&bull;" }} />;
			}

			return <br />;
		}

		return null;
	}

	useEffect(() => {
		const socket = socketIOClient(process.env.GATSBY_WS_ENDPOINT);

		socket.on("connect", () => socket.emit("userJoin", "animal"));
		socket.on("disconnect", () => socket.open());
		socket.on("userJoin", ({ numUsers }) => dispatch({
			type: "users",
			payload: numUsers,
		}));
		socket.on("userLeft", ({ numUsers }) => dispatch({
			type: "users",
			payload: numUsers,
		}));

		hit();

		// window.Hls = Hls;

		return () => socket.close();
	}, []);

	useEffect(() => {
		if (data) {
			dispatch({
				type: "broadcast",
				payload: data,
			});
		}
	}, [data]);

	useEffect(() => {
		if (isMobile && isLandscape && !isFullscreen) {
			setFull();
			dispatch({
				type: "plays",
				payload: true,
			});
		} else if (!isLandscape && isFullscreen) {
			exitFull();
		}
	}, [isLandscape]);

	const { broadcast, users, plays, volume, mute } = state;

	let closestEventTime = new Date(get(broadcast, "schedules[0].begins_at", [])).getTime() - new Date().getTime();
	let position = 0;

	for (let i = 0; i < get(broadcast, "schedules", []).length; i++) {
		if (new Date(get(broadcast, `schedules[${i}].begins_at`, [])).getTime() - new Date().getTime() < Math.abs(closestEventTime)) {
			closestEventTime = new Date(get(broadcast, `schedules[${i}].begins_at`, [])).getTime() - new Date().getTime();
			position = i;
		}
	}
	const broadcastBeginsAt = new Date(get(broadcast, "begins_at", null)).getTime() - new Date().getTime() > 0 ? get(broadcast, "begins_at", null) : false;
	const currentEvent = get(broadcast, `schedules[${position}]`, []);

	const futureEvents = get(broadcast, "schedules", []).filter(event => new Date(get(event, "begins_at", null)) > new Date());
	const preview = get(broadcast, "preview.src", "");
	// const previewMobile = get(broadcast, "preview_mobile.src", "");
	const dateTime = get(futureEvents, "[0].begins_at", null);
	const broadcastName = get(futureEvents, "[0].data.title", "");

	const src = get(broadcast, "src", "");

	let enabled = items.some(({ slug: currentSlug, enabled }) => currentSlug === slug && enabled);

	if (typeof window !== "undefined") {
		const search = parse(get(location, "search", {}));

		if (has(search, "enabled")) {
			enabled = parse(get(location, "search", {})).enabled;
		}
	}

	return (
		<div className={"broadcast"}>
			<div className={"broadcast__body"}>
				<div
					className={"mb-3 broadcast__video"}
					onMouseLeave={
						() => dispatch({
							type: "controls",
							payload: false,
						})
					}
					onMouseEnter={
						() => dispatch({
							type: "controls",
							payload: true,
						})
					}
					style={
						{
							lineHeight: `${enabled ? 0 : "inherit"}`,
							backgroundImage: `${enabled ? "null" : `url(${preview}) `}`,
							border: `${enabled ? 0 : "null"}`,
							height: `${isMobile && enabled ? "auto" : "null"}`,
						}
					}
				>
					{
						enabled && (
							<Player
								ref={video}
								dispatch={dispatch}
								src={src}
								plays={plays}
								volume={volume}
								mute={mute}
							/>
						)
					}

					{
						!enabled && (
							<div className={"p-3 d-flex align-items-center broadcast__info"}>
								<Icon
									id={"broadcast"}
									className={"broadcast__icon"}
									style={
										{
											display: "block",
										}
									}
								/>
								<div>
									{
										broadcastBeginsAt || dateTime ? (
											<Countdown
												dateTime={broadcastBeginsAt || dateTime}
												className={cx({ "d-none": enabled })}
												users={users}
												mount={!enabled}
											/>) : "Вещаний не запланировано"
									}
								</div>
							</div>
						)
					}
					{
						enabled && (
							<Controls
								{...state}
								onPlay={
									payload => dispatch({
										type: "plays",
										payload,
									})
								}
								onMute={
									payload => dispatch({
										type: "mute",
										payload,
									})
								}
								onVolume={
									payload => dispatch({
										type: "volume",
										payload,
									})
								}
								toggleFull={toggleFull}
							/>
						)
					}
				</div>
				{
					!!broadcastName && (
						<div
							className={"h5 mb-3 broadcast__name"}
							itemProp={"name"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								color: theme.color.text,
								fontSize: theme.fontSize.h4,
							})}
						>
							{enabled ? get(currentEvent, "data.title", "") : broadcastName}
						</div>
					)
				}
				{
					srcDescription && (
						<div
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.text,
							})}
							className={`desc-color-${theme.color.key}`}
						>
							{srcDescription}
						</div>
					)
				}
				{
					!!dateTime && (
						<div
							className={"broadcast__date"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								color: theme.color.text,
								fontSize: theme.fontSize.h4,
							})}
						>
							{makeDelimeter(users)} {enabled ? "Трансляция началась" : "Запланировано на"} {moment(enabled ? get(currentEvent, "begins_at", "") : dateTime).locale("ru").format("DD MMMM YYYY, HH:mm")}
						</div>
					)
				}
			</div>
		</div>
	);
}

export default withOrientationChange(Broadcast);

Broadcast.propTypes = {
	data: PropTypes.object,
	height: PropTypes.number,
	query: PropTypes.object,
	slug: PropTypes.string,
	isLandscape: PropTypes.bool,
	srcDescription: PropTypes.string,
};

Broadcast.defaultProps = {
	data: {},
	height: 400,
	query: {},
	slug: PropTypes.string,
	isLandscape: false,
	srcDescription: "",
};
