import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { countdown } from "../../../utils";

import "./Countdown.scss";

export default function Countdown({ dateTime, mount }) {
	const [countDown, setCountDown] = useState(null);
	const [imeInterval, setTimeInterval] = useState(-1);

	useEffect(() => {
		clearInterval(imeInterval);
		setTimeInterval(countdown(dateTime, setCountDown));
	}, [dateTime]);

	return !!countDown && !!mount ? (
		<span
			className={"broadcast__countdown"}
			itemProp={"startDate"}
			content={dateTime}
		>
			До начала {countDown}
		</span>

	) : null;
}

Countdown.propTypes = {
	dateTime: PropTypes.string,
	className: PropTypes.string,
	mount: PropTypes.bool,
};

Countdown.defaultProps = {
	dateTime: new Date().toJSON(),
	className: "",
	mount: false,
};
