import React, { useState, useEffect, useMemo } from "react";
import { get, last, compact } from "lodash";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Helmet from "react-helmet";

import Pages from "../../components/Pages";
import { ContentBlock, TextBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import { makeUrl, removePreloader, markdownConverter } from "../../helpers";
import { Share } from "../../widgets";
import Broadcast from "../../components/Broadcast";
import Schedule from "../../components/Schedule";
import NoScript from "../../components/NoScript";

import "./Broadcast.scss";

// FIXME: Move queries to queries.graphql

export const query = graphql`
	query BradcastQuery($slug: String!) {
		hasura {
			broadcasts(where: {slug: {_eq: $slug}}) {
				type
				src
				slug
				enabled
				begins_at
				title_full
				content_blocks
				main_image
				main_image_preview
				preview
				preview_mobile
				seo
				settings
				schedules(order_by: {begins_at: asc}) {
					begins_at
					data
				}
			}
			v_broadcasts(where: {slug: {_eq: $slug}}) {
				slug
				enabled
				begins_at
				title_full
				content_blocks
				building_id
				building_type
				schedules(order_by: {begins_date: asc}) {
					begins_date
					events
				}
			}
		}
	}
`;

const FETCH_QUERY = gql`
	query broadcastQuery($slug: String) {
		broadcasts(where: {slug: {_eq: $slug}}) {
			type
			src
			slug
			enabled
			begins_at
			title_full
			content_blocks
			main_image
			main_image_preview
			preview
			preview_mobile
			seo
			settings
			schedules(order_by: {begins_at: asc}) {
				begins_at
				data
			}
		}
		v_broadcasts(where: {slug: {_eq: $slug}}) {
			slug
			enabled
			begins_at
			title_full
			content_blocks
			building_id
			building_type
			schedules(order_by: {begins_date: asc}) {
				begins_date
				events
			}
		}
	}
`;

export default function Broadcasts({ data, location }) {
	const path = last(compact(location.pathname.split("/")));

	const [broadcastData, setBroadcastData] = useState(get(data, "hasura", {}));

	const { loading, data: fetchData, error } = useQuery(FETCH_QUERY, {
		variables: {
			slug: path,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	});

	useEffect(() => {
		if (fetchData && !error) {
			setBroadcastData(fetchData);
		}

		removePreloader(!loading || error);
	}, [loading]);

	const broadcasts = get(broadcastData, "broadcasts[0]", {});
	const url = makeUrl.broadcast(broadcasts);
	const scheduleData = get(broadcastData, "v_broadcasts[0]", {});
	const title_full = get(broadcastData, "broadcasts[0].title_full", "");
	const content_blocks = get(broadcastData, "broadcasts[0].content_blocks", []);
	const srcDescription = markdownConverter(get(broadcastData, "broadcasts[0].settings.srcDescription", []));

	const locationMeta = {
		"@context": process.env.REACT_APP_BASE_FRONT_URL,
		"@type": "ChildrensEvent",
		"location": {
			"@type": "VirtualLocation",
			"url": `${process.env.REACT_APP_BASE_FRONT_URL}/broadcast/broadcast`,
		},
	};

	const broadcast = useMemo(() => (
		<ContentBlock key={"broadcast"}>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(locationMeta)}
				</script>
			</Helmet>
			<div className={"container"} itemScope itemType="http://schema.org/BroadcastEvent">
				<div itemProp={"location"} className={"d-none"} itemScope itemType="http://schema.org/Place">
					<span itemProp="name">{title_full}</span>
				</div>
				<TextBlock
					title={title_full}
					titleClassName={"text-block__title_text-center"}
				>
					<Broadcast slug={path} data={broadcasts} srcDescription={srcDescription} />
					<NoScript>
						<p>Чтобы посмотреть трансляцию, включите JavaScript</p>
					</NoScript>
				</TextBlock>
			</div>
		</ContentBlock>
	), [broadcastData]);

	const content = useMemo(() => (
		<ContentBlock key={"main-content"} mount={content_blocks && !!content_blocks.length}>
			<div className={"container"}>
				<Contents items={content_blocks} />
			</div>
		</ContentBlock>
	), [content_blocks]);

	const schedule = useMemo(() => (
		<ContentBlock key={"schedule"}>
			<div className={"container"}>
				<Schedule
					slug={path}
					scheduleData={scheduleData}
				/>
			</div>
		</ContentBlock>
	), [scheduleData]);

	return (
		<Pages url={url} entity={get(broadcastData, "broadcasts[0]", {})}>
			{
				[
					broadcast,
					content,
					schedule,
				]
			}
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Видеотрансляции"} />
			</div>
		</Pages>
	);
}

Broadcasts.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
};

Broadcasts.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
};
