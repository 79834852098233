import React, { forwardRef } from "react";
import ReactPlayer from "react-player/file";
import { notification } from "antd";
import { useDebounceFn } from "@umijs/hooks";
import PropTypes from "prop-types";

export const initialState = {
	broadcast: {},
	plays: false,
	mute: false,
	volume: 1,
	loading: true,
	disabled: true,
	error: "",
	controls: false,
	users: 1,
};

export function reducer(state, { type, payload }) {
	switch (type) {
		case "broadcast": {
			return {
				...state,
				broadcast: payload,
			};
		}
		case "plays":
			return {
				...state,
				plays: payload,
			};
		case "mute":
			return {
				...state,
				mute: payload,
			};
		case "volume":
			return {
				...state,
				volume: payload,
			};
		case "loading":
			return {
				...state,
				loading: payload,
			};
		case "disabled":
			return {
				...state,
				disabled: payload,
			};
		case "error":
			return {
				...state,
				error: payload,
			};
		case "controls":
			return {
				...state,
				controls: payload,
			};
		case "users":
			return {
				...state,
				users: payload,
			};
		default:
			throw new Error();
	}
}

const Player = forwardRef((props, ref) => {
	const { src, plays, volume, mute, dispatch } = props;

	const { run: play } = useDebounceFn(() => {
		dispatch({
			type: "plays",
			payload: true,
		});
	}, 500);

	return (
		<ReactPlayer
			ref={ref}
			className={"react-player"}
			width={"100%"}
			height={"100%"}
			url={src}
			playing={plays}
			controls={false}
			loop={false}
			volume={volume}
			muted={mute}
			config={{
				file: {
					hlsOptions: {
						autoStartLoad: true,
						startPosition: -1,
						debug: false,
						capLevelOnFPSDrop: false,
						capLevelToPlayerSize: false,
						defaultAudioCodec: undefined,
						initialLiveManifestSize: 1,
						maxBufferLength: 30,
						maxMaxBufferLength: 600,
						maxBufferSize: 10 * 1000 * 1000,
						maxBufferHole: 0.1,
						lowBufferWatchdogPeriod: 0.5,
						highBufferWatchdogPeriod: 3,
						nudgeOffset: 0.1,
						nudgeMaxRetry: 3,
						maxFragLookUpTolerance: 0.25,
						liveSyncDurationCount: 3,
						liveMaxLatencyDurationCount: Infinity,
						liveDurationInfinity: false,
						liveBackBufferLength: Infinity,
						enableWorker: true,
						enableSoftwareAES: true,
						manifestLoadingTimeOut: 10000,
						manifestLoadingMaxRetry: 1,
						manifestLoadingRetryDelay: 1000,
						manifestLoadingMaxRetryTimeout: 64000,
						startLevel: undefined,
						levelLoadingTimeOut: 10000,
						levelLoadingMaxRetry: 4,
						levelLoadingRetryDelay: 1000,
						levelLoadingMaxRetryTimeout: 64000,
						fragLoadingTimeOut: 20000,
						fragLoadingMaxRetry: 6,
						fragLoadingRetryDelay: 1000,
						fragLoadingMaxRetryTimeout: 64000,
						startFragPrefetch: false,
						testBandwidth: true,
						fpsDroppedMonitoringPeriod: 5000,
						fpsDroppedMonitoringThreshold: 0.2,
						appendErrorMaxRetry: 3,
						enableWebVTT: true,
						enableCEA708Captions: true,
						stretchShortVideoTrack: false,
						maxAudioFramesDrift: 1,
						forceKeyFrameOnDiscontinuity: true,
						abrEwmaFastLive: 3.0,
						abrEwmaSlowLive: 9.0,
						abrEwmaFastVoD: 3.0,
						abrEwmaSlowVoD: 9.0,
						abrEwmaDefaultEstimate: 500000,
						abrBandWidthFactor: 0.95,
						abrBandWidthUpFactor: 0.7,
						abrMaxWithRealBitrate: false,
						maxStarvationDelay: 4,
						maxLoadingDelay: 8,
						minAutoBitrate: 0,
						emeEnabled: false,
						widevineLicenseUrl: undefined,
						drmSystemOptions: {},
					},
				},
			}}
			onReady={
				() => dispatch({
					type: "disabled",
					payload: false,
				})
			}
			onStart={() => console.log("onStart")}
			onPause={
				() => dispatch({
					type: "plays",
					payload: false,
				})
			}
			onSeek={e => console.info("onSeek", e)}
			onBuffer={
				() => {
					dispatch({
						type: "loading",
						payload: true,
					});
					dispatch({
						type: "error",
						payload: false,
					});
				}
			}
			onBufferEnd={
				() => dispatch({
					type: "loading",
					payload: false,
				})
			}
			onError={(error, { details, fatal, type }) => {
				dispatch({
					type: "plays",
					payload: false,
				});

				if (fatal) {
					dispatch({
						type: "disabled",
						payload: true,
					});
					dispatch({
						type: "error",
						payload: error,
					});

					notification.error({
						message: "Ошибка трансляции",
						description: error,
					});

					console.error({
						error,
						details,
						type,
					});
				} else {
					console.warn({
						error,
						details,
						type,
					});

					play();
				}
			}}
		/>
	);
});

Player.propTypes = {
	src: PropTypes.string,
	plays: PropTypes.bool,
	volume: PropTypes.number,
	mute: PropTypes.bool,
	dispatch: PropTypes.func,
};

Player.defaultProps = {
	src: "",
	plays: false,
	volume: 1,
	mute: false,
	dispatch: () => Function,
};

Player.displayName = "BroadcastPlayer";

export default Player;
