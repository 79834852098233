import React from "react";
import PropTypes from "prop-types";
import { Icon, Slider, Button } from "antd";
import { isIOS } from "react-device-detect";
import cx from "classnames";

export default function Controls({ plays, volume, mute, loading, disabled, error, controls, onPlay, onMute, onVolume, toggleFull }) {
	return (
		<div
			id={"broadcast-controls"}
			className={"broadcast__controls"}
		>
			{
				!error && (
					<div className={"broadcast__wrapper"}>
						<Button
							onClick={() => onPlay(!plays)}
							disabled={disabled}
							className={"broadcast__play-pause p-0"}
						>
							<Icon
								type={cx({ "play-circle": !plays })}
								style={
									{
										display: "flex",
										fontSize: 100,
										color: "white",
										opacity: "0.8",
									}
								}
							/>
						</Button>
					</div>
				)
			}

			<div
				className= {
					cx(
						"broadcast__group p-1 p-md-3",
						{ "broadcast__group_is-active": !plays || controls },
					)
				}
			>
				<div className={"d-flex"}>
					<Button
						id={"playpause"}
						onClick={() => onPlay(!plays)}
						disabled={disabled}
						className={"broadcast__group-element play"}
						title={"Воспроизвести"}
						style={
							{
								marginRight: 16,
							}
						}
					>
						<Icon
							type={plays ? "pause-circle" : "play-circle"}
							style={
								{
									display: "flex",
									fontSize: 35,
								}
							}
						/>
					</Button>
					<Button
						id={"mute"}
						onClick={() => onMute(!mute)}
						disabled={disabled}
						className={"broadcast__group-element mute"}
						title={"Выключить звук"}
						style={
							{
								marginRight: 16,
							}
						}
					>
						<Icon
							type={"sound"}
							style={
								{
									display: "flex",
									fontSize: 35,
								}
							}
							theme={cx({ filled: !mute })}
						/>
					</Button>
					{
						!isIOS && (
							<Slider
								min={0}
								max={1}
								step={0.01}
								onChange={volume => onVolume(volume)}
								disabled={mute || disabled}
								value={volume}
								tooltipVisible={false}
								style={
									{
										width: 100,
									}
								}
							/>
						)
					}
					{
						loading && (
							<div className={"d-flex align-items-center ml-3"}>
								<Icon
									type="loading"
									style={{
										fontSize: 24,
									}}
									spin
								/>
							</div>
						)
					}
				</div>
				{
					!isIOS && (
						<Button
							id={"fullscreen"}
							onClick={toggleFull}
							className={"broadcast__group-element fullscreen"}
							title={"На весь экран"}
							disabled={disabled}
						>
							<Icon
								type={"fullscreen"}
								style={
									{
										display: "flex",
										fontSize: 35,
									}
								}
							/>
						</Button>
					)
				}
			</div>
		</div>
	);
}

Controls.propTypes = {
	plays: PropTypes.bool,
	volume: PropTypes.number,
	mute: PropTypes.bool,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	error: PropTypes.string,
	controls: PropTypes.bool,
	onPlay: PropTypes.func,
	onMute: PropTypes.func,
	onVolume: PropTypes.func,
	toggleFull: PropTypes.func,
};

Controls.defaultProps = {
	plays: false,
	volume: 1,
	mute: false,
	loading: false,
	disabled: false,
	error: "",
	controls: true,
	onPlay: () => Function,
	onMute: () => Function,
	onVolume: () => Function,
	toggleFull: () => Function,
};
