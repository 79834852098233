/* eslint-disable no-use-before-define */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import moment from "moment";
import { Link } from "gatsby";
import { Button, Dropdown, Icon, Menu } from "antd";
import { isAndroid } from "react-device-detect";

import { BroadcastContext } from "../../widgets";
import { makeUrl, removeEmptyKeys } from "../../helpers";
import { useThemeContext } from "../Layout/ThemeContext";

import "./Schedule.scss";

export default function Schedule({ slug, scheduleData }) {
	const { theme } = useThemeContext();
	const { items } = useContext(BroadcastContext);

	const [enabled, setEnabled] = useState(false);

	const schedules = get(scheduleData, "schedules", []);
	const building_id = get(scheduleData, "building_id", "");
	const building_type = get(scheduleData, "building_type", "");
	const scheduleEnabled = get(scheduleData, "enabled", false);

	const webcalLink = `webcal://serafim.help/api/calendar/export_broadcasts_schedule.ics?id=${building_id}&type=${building_type}`;
	const httpsLink = `https://serafim.help/api/calendar/export_broadcasts_schedule.ics?id=${building_id}&type=${building_type}`;
	const path = isAndroid ? httpsLink : webcalLink;

	const links = [
		{
			path,
			title: "Apple",
			icon: "apple",
		},
		{
			path,
			title: "Google",
			icon: "google",
		},
		{
			path,
			title: "Outlook",
			icon: "windows",
		},
	];

	useEffect(() => {
		setEnabled(items && items.some(({ enabled, slug: currentSlug }) => currentSlug === slug && enabled) || scheduleEnabled);
	}, [scheduleData]);

	return (
		<div className={"mb-5 schedule"}>
			<div className={"row mb-4 schedule-top"}>
				<div className={"col-md-6 col-lg-6 col-xs-12 schedule-top__title"}>
					<h4
						style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.h4,
							lineHeight: theme.lineHeight.h4,
							color: theme.color.text,
						})}
					>
						Расписание
					</h4>
				</div>
				<div className={"col-md-6 col-lg-6 col-xs-12 schedule-top__button"}>
					<Dropdown
						placement={"bottomLeft"}
						trigger={["click"]}
						overlayClassName={"schedule__add"}
						overlay={
							<Menu>
								{
									links.map(({ path, title, icon }) => (
										<Menu.Item key={path}>
											<a
												href={path}
												// eslint-disable-next-line react/jsx-no-target-blank
												target={"_blank"}
												style={removeEmptyKeys({
													color: theme.color.body,
													backgroundColor: theme.color.text,
													textAlign: "left",
												})}
											>
												<Icon
													type={icon}
													style={
														{
															fontSize: 18,
															marginRight: 8,
														}
													}
												/>
												<span>
													{title}
												</span>
											</a>
										</Menu.Item>
									))
								}
							</Menu>
						}
					>
						<Button
							style={removeEmptyKeys({
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							<Icon
								type="calendar"
								style={removeEmptyKeys({
									color: theme.color.body,
									backgroundColor: theme.color.text,
								})}
							/>
							Добавить в календарь
						</Button>
					</Dropdown>
				</div>
			</div>
			<div
				className={"schedule-wrapper"}
				itemScope
				itemType="http://schema.org/Event"
			>
				<table
					className={"schedule-table"}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
						backgroundColor: theme.color.body,
					})}
				>
					<thead>
						<tr>
							<td colSpan={"2"} style={removeEmptyKeys({ borderColor: theme.color.text })}>ДЕНЬ</td>
							<td style={removeEmptyKeys({ borderColor: theme.color.text })}>ВРЕМЯ</td>
							<td style={removeEmptyKeys({ borderColor: theme.color.text })}>СЛУЖБА</td>
							<td style={removeEmptyKeys({ borderColor: theme.color.text })}>ПРАЗДНИК</td>
						</tr>
					</thead>
					<tbody>
						{ makeScheduleTable(schedules, enabled)}
					</tbody>
				</table>
			</div>
		</div>
	);
}

function makeScheduleTable(data, enabled) {
	const weekDay = {
		1: "Пн",
		2: "Вт",
		3: "Ср",
		4: "Чт",
		5: "Пт",
		6: "Сб",
		0: "Вс",
	};

	const Months = {
		0: "января",
		1: "февраля",
		2: "марта",
		3: "апреля",
		4: "мая",
		5: "июня",
		6: "июля",
		7: "августа",
		8: "сентября",
		9: "октября",
		10: "ноября",
		11: "декабря",
	};

	const { theme } = useThemeContext();

	const style = removeEmptyKeys({ borderColor: theme.color.text });
	let findClosestEvent = false;

	return (
		data.map((day, didx) => {
			const events = get(day, "events", []);
			const beginsDate = get(day, "begins_date", "");
			const holiday = get(day, "events[0].data.holiday", "");

			const displayDate = `${new Date(beginsDate).getDate()} ${Months[new Date(beginsDate).getMonth()]}`;

			return (
				events.map((event, idx) => {
					let fillCurrentColor = false;
					const eventDateTime = moment(get(event, "begins_at", ""));
					const eventMonth = eventDateTime.month();
					const eventDate = eventDateTime.date();
					const eventHour = eventDateTime.hour();
					const currentDateTime = moment();
					const currentMonth = currentDateTime.month();
					const currentDate = currentDateTime.date();
					const currentHour = currentDateTime.hour();
					const nextTime = get(events, `${[idx + 1]}`);

					if (enabled && !findClosestEvent &&
						eventDate === currentDate &&
						eventHour <= currentHour &&
						(nextTime === undefined || moment(get(nextTime, "begins_at", "")).hour() > currentHour)
					) {
						fillCurrentColor = true;
						findClosestEvent = true;
					}

					if (!enabled && !findClosestEvent) {
						if (eventDate === currentDate && eventHour > currentHour || eventDateTime.isAfter(currentDateTime) && idx === 0) {
							fillCurrentColor = true;
							findClosestEvent = true;
						}

						const prevEvents = data[didx - 1] || {};

						if (moment(get(prevEvents, `events[${get(prevEvents, "events.length", "") - 1}].begins_at`, "")).hour() <= currentHour && eventDate >= currentDate + 1 && eventMonth >= currentMonth && idx === 0) {
							fillCurrentColor = true;
							findClosestEvent = true;
						}
					}

					const begins_at = moment(get(event, "begins_at", "")).format("HH:mm");
					const title = get(event, "data.title", "");
					const calendarPath = makeUrl.day({ type: "calendar",
						currentDay: moment(get(event, "begins_at", "")).format("YYYY-MM-DD") });

					return (
						idx === 0 ?
							<tr key={idx}>
								<td
									rowSpan={events.length}
									style={Object.assign({}, { padding: 0 }, { ...style })}
									itemProp="startDate"
									content={get(event, "begins_at", "")}
									nowrap={"nowrap"}
								>
									<Link to={calendarPath} className={"calendar-link"}
										style={removeEmptyKeys({
											fontFamily: theme.fontFamily,
											fontSize: theme.fontSize.text,
											lineHeight: theme.lineHeight.text,
											color: theme.color.text,
											backgroundColor: theme.color.body,
										})}
									>
										{displayDate}
									</Link>
								</td>
								<td rowSpan={events.length} style={style}>{weekDay[moment(beginsDate).day()]}</td>
								<td style={Object.assign({}, { backgroundColor: fillCurrentColor ? "#00000021" : "" }, { ...style })} itemProp="doorTime">{begins_at}</td>
								<td style={Object.assign({}, { backgroundColor: fillCurrentColor ? "#00000021" : "" }, { ...style })} itemProp="name">{title}</td>
								<td rowSpan={events.length} style={style}>{holiday}</td>
							</tr> :
							<tr key={idx} style={{ backgroundColor: fillCurrentColor ? "#00000021" : "" }}>
								<td style={style}>{begins_at}</td>
								<td style={style}>{title}</td>
							</tr>
					);
				})
			);
		})
	);
}

Schedule.propTypes = {
	query: PropTypes.object,
	slug: PropTypes.string,
	scheduleData: PropTypes.object,
};
